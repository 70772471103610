.modal {
    z-index: 100;
    position: relative;
    width: 400px;
    height: fit-content;
    text-align: center;
    border-radius: 2px;
    background: #fff;
    padding: 15px;
    display: block;
}

.overlay {
    z-index: 90;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(51, 51, 51, 0.5);
}
